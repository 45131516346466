<template>
<div id="app-master">

    <AppNavigation />

  <div id="page">
    <main>
      <router-view />
    </main>
  </div>

  <!-- <div class="container">
      <small>{{ user }}</small>

  </div> -->
    <MasterFooter />

</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import {mapState} from 'vuex'

import AppNavigation from '@/components/AppNavigation.vue'
import MasterFooter from '@/components/MasterFooter.vue'


 
export default {
  name: 'AppMaster',
  components: {
    AppNavigation,
    MasterFooter,
  },
  data: function() {
    return {
      navIsShown: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.testMe();
  },
  methods: {
    testMe: function() {
      const currentUser = this.$store.state.user;
      if(currentUser.firstName !== null) {
        if(currentUser.firstName.length === 0) {
          this.$store.dispatch("sendUserToWelcomeFlow");
        }
      }


    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/style.scss"></style>
