<template>
<div>
  <div id="navigation" v-if="user.userId">
    <div class="logo">
      <img src="~@/assets/excelerate-symbol.png" class="symbol">
    </div> 
    <nav class="navigation-routes">
      <router-link to="/events" v-if="user.role === 'Student'"><i class="fas fa-calendar-alt"></i> <span>Events</span></router-link>
      <router-link to="/dashboard" v-if="user.userId"><i class="far fa-star"></i> <span>Dashboard</span></router-link>
      <router-link to="/collaboration/my" v-if="user.role === 'Student'"><i class="fas fa-hands-helping"></i> <span>My Thesis Topics</span></router-link>
      <router-link :to="'/companies/' + user.company" v-if="user.role === 'Professional' && user.company"><i class="fas fa-user-tie"></i> <span>Your company</span></router-link>
      <router-link to="/company/new" v-if="user.role === 'Professional' && user.company && user.company.length == 0"><i class="fas fa-plus"></i> <span>Register company</span></router-link>
      <router-link to="/collaborations" v-if="user.role === 'Professional' && user.company"><i class="fas fa-search"></i> <span>Find Thesis Topics</span></router-link>
      <router-link to="/collaborations" v-if="user.role === 'Student'"><i class="fas fa-search"></i> <span>Browse Thesis Topics</span></router-link>
      <!-- <router-link to="/profile"><i class="fas fa-user"></i> <span>user profile</span></router-link> -->
    </nav>
  </div>
  <div v-else class="thesis-profile">
     <div class="logo">
      <img src="~@/assets/excelerate-symbol.png" class="symbol">
    </div> 
  </div> 
</div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'AppNavigation',

    computed: {
      ...mapState(['user']),
    },
  methods: {

  }
}
</script>
<style scoped>
.thesis-profile .logo .symbol {
    margin: 20px 0 0 15px;

}

</style>
